// #slaw1{
//     height: 450px;
//     // width: 330px;
//     max-width: 1000px;
// }
// #slaw2{
//     height: 500px;
//     max-width: 1000px;

// }
// // #slaw3{
// //     width: 507px;
// // }
// #slaw7{
//     // width: 1000px;
//     height: 500px;
//     max-width: 1000px;

// }
// #slaw8{
//     height: 600px;
//     // width: 700px;
//     max-width: 1000px;

// }

.project-detail-container{
    .case-study{
        img{
            border-radius: 15px;
            box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
        }
    }  
    .case-study-section{
        max-width: 1000px;
        // height: 500px;
    margin-left: auto;
    margin-right: auto;
        p{
            margin: 13px 0;
        }
        h2{
            margin-top: 30px;
        }
    }
}

.section-global-new {
    padding: 0px 0;
    border-bottom: 0px solid var(--border-brutal);
}
#slaw2{
    background-color: white;
}


#portfolio-btn{
    margin-right: 35px;
    margin-top: 40px;
}

@media(max-width:640px){
    #portfolio-btn{
        margin-left: 55px !important;
        margin-top: 40px;
    
}
}
@media(max-width:768px){
    #portfolio-btn{
        margin-left: 15px;    
}
.btn {
    padding: 0px 5px 0px 5px !important;}

}