#contactimage{
    height: 350px;
    width: 400px;
}
#contactimage:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 800ms;

}

#contct{
    background-color: white;
}
.contactway{
    width: 400px;
    padding-left: 50px;
}
#contacthead{
    font-size: 35px;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 1px;
    // padding-bottom: 70px;
}
.contact-container{
    .contact-link{
        text-decoration: none;
        .contact-link-container{
            box-shadow: 0 0 0 1px var(--border-brutal);
            border-radius: 15px;
            padding: 10px;
            transition: 0.3s;
            &:hover{
                background: rgba(0,0,0,0.03);
            }
        }
    }
}
// .contactimg{
//     padding-top: 50px;
// }
#maincontact{
    background-color: #e7f0fd;
}
#submit{
    background-color: rgb(224 69 102);

}

@media(max-width:500px){
    #contactimage{
        height: 265px;
        width: 400px;
    }
    
}





