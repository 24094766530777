.privacy-policy{
    font-family: Arial, Helvetica, sans-serif;
    margin-top: -55px;

}

.privacy-policy p {
    font-size: 18px;
    
}
.privacy-policy h2{
    font-size: 30px;
}
#privacy{
    background-image: linear-gradient( to right,#6b8cd2,#91EAE4,white);
}