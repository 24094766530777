.project-detail-container{
    .case-study{
        img{
            border-radius: 15px;
            box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
        }
    }  
    .case-study-section{
        max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
        p{
            margin: 13px 0;
        }
        h2{
            margin-top: 30px;
        }
    }
}

.section-global-new {
    padding: 0px 0;
    border-bottom: 0px solid var(--border-brutal);
}
#habari2{
    background-color: white;
}
.image-container{
    display: flex;
    flex-wrap: wrap;
    


}
.image-container img{
width: 460px;
height: auto;
margin: 7px;
// display: inline-block;

}
@media(max-width:768px){
    .image-container img {
        // width: 100%;
        width: 460px;
    }
}
#display {
margin-top:-4rem;
}
// #habari-bgclr{
//     background-color: rgb(88, 2, 52);
//     background-image: linear-gradient( to right, rgb(88, 2, 52), rgb(175, 84, 137),#e0c3d2);

// }

#color-portfolio{
    color: rgb(5, 5, 82);
}