

body{
    // background-image: linear-gradient( to right,#3f85b3,#6dd5fa,#fff);
    overflow-x: hidden;
    background-color: #e7f0fd;
}
#imgstyle1{
    width: 450px;
}
#testimonial-clr{
background-color: #fff;
}
#service-clr{
  color: rgb(2, 2, 56);  
}
#clr1{
    background-color:white;
}
.homeimg{
    width: 450px;
    // aspect-ratio: 1 / 1;
    height: 400px;
    // padding-left: 200px;
}
#pro-btn{
    padding-left: 1100px;
}
#seeall{
margin-top: 40px;    
}
#partner:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: 700ms;
}
#home2{
    width: 620px;
    // height: 500px;
    justify-content: space-between;
}
#image1{
    display: flex;
}
#pic3{
    width: 1000px;
} 
 
#imgstyle1:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: 700ms;

}
#pic3:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 800ms;

}
#project-btn{
    background-color: green;
}
#getintouch{
    background-color: rgb(224 69 102);
}
#testimonal{
    background-color: white;
}

// .project{
//     color: rgb(199, 0, 116);
// }
#img2{
    border-radius: 20% 20% 20% 0;
    width: 430px;
    height: 300px;
    box-shadow: 0.4rem 0.4rem  black;

}
#img2:hover{
    cursor: pointer;
    transform: scale(1.04);
    transition: 900ms;
}
#button{
    background-color: rgb(158, 0, 0);
}
.container1{
    display: flex;
}
.partner1{
    width: 390px;
    height: 370px;
    padding-bottom: 320px;
}
.partner2{
    width: 210px;
    height: 208px;
    padding-bottom: 137px;
    
}
#paragraph1{
font-size: 20px;
font-family: Arial, Helvetica, sans-serif;
}
#paragraph{
    font-family:Arial, Helvetica, sans-serif;
}
.counter{
    margin-top: 50px;
    @media (max-width: 575.98px){
        display: flex;
        justify-content: space-between;
    }
}
.partner3{
    width: 200px;
    height: 200px;
    padding-bottom: 160px;
    padding-left: 90px;
}

#section{
    background-color:white;
}
.H1{
    color: rgb(6, 0, 72);
    font-size: 50px;


}
.it{

    animation: float 3s ease-in-out infinite;

    @keyframes float {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-10px);
        }
        100% {
            transform: translatey(0px);
        }
    }
    
}            
  
#p{
    padding-bottom: 90px;
}
.section {
    background-color: white;
}
.txt-1 {
    font-size: 18px;
}
.H2{
    color: rgb(6, 0, 72);

}
// @media (max-width: 600px) {
//     .project{
//         width: 590px;
//         height: 350px;
//         cursor: pointer;
//         overflow-y: hidden;
//     }
//   }
    .project{
        width: 590px;
        height: 350px;
        cursor: pointer;
        overflow-y: hidden;
    }

.projects{
    display: flex;
    justify-content: space-between;
    column-gap: 50px;
}
.project {
    height: 250px;
    cursor: pointer;
}
.project:hover{
    opacity: 0.3;
}
// .middle {
//     transition: .5s ease;
//     opacity: 0;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     -ms-transform: translate(-50%, -50%);
//     text-align: center;
//   }
.aboutimg{
    width: 490px;
    height: 400px;
}
.aboutimg1{
    width: 200px;
    height: 20px;
}
 
.consulting{
    .cta-form{
        background: #fefefe;
        border-radius: 13px;
        // overflow: hidden;
        padding: 5px;
        flex-wrap: wrap;
        box-shadow: 0 0 0 1px var(--border-brutal);
        @media (max-width: 575.98px){
            padding: 0;
            background: transparent;
            box-shadow: none;
        }
        .cta-input{
            flex-grow: 1;
            @media (max-width: 575.98px){
                margin-bottom: 16px;
            }
            input{
                border: 0;
                width: 100%;
                border-radius: 10px;
                line-height: 48px;
                padding: 0 5px 0 25px;
                box-shadow: none;
                // &:focus{box-shadow: 0 0 0 1px var(--border-brutal);}
                @media (max-width: 575.98px){
                    box-shadow: 0 0 0 1px var(--border-brutal);
                }
            }
        }
        .cta-btn{
            @media (max-width: 575.98px){
                width: 100%;
                display: flex;
            }
            .btn{
                width: 100%;
            }
        }
    }
}
.counter{
    padding-top: 50px;
    margin-top: 50px;
    @media (max-width: 575.98px){
        display: flex;
        justify-content: space-between;
    }
    .count{
        padding: 0 40px;
        border-left: 1px solid var(--border-brutal);
        &:nth-child(1){
            padding-left: 0;
            border-left: 0;
        }
        @media (max-width: 575.98px){
            padding: 0 16px;
            border: 0;
        }
    }
}
.buttn-style{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
@media(max-width:320px){
    .align-self-center {
        margin-top: 20px;
    }
    .testimonial-user .d-flex.mt-auto .tsx-3{
        margin-bottom: -3px;
    }
    .testimonial-user.d-flex.mt-auto .ms-3 .txt-3{
        font-size: 10px;
        margin-bottom: -2px;
    }
    div#testmo .tesimonial-brand-image {
        margin-bottom: -18px;
    }
    .service .txt-2{
        font-size: 16px;
    }
    .col-lg-6.align-self-center p {
        font-size: 16px;
    }
    .col-lg-6.align-self-center p {
        font-size: 16px !important;
    }
    section.section-global.case-study p {
        font-size: 16px;
    }
    .btn.btn-outline {
        margin-top: 0px !important;
    }
}


.btn.btn-outline {
    margin-top: 50px;
}



// watsap //


.tooltip-text {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 10%;
    top: 20%;
    transform: translateX(-50%);
    background-color: #49d57b;
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 16px;
    width: 130px;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
}
a:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

#display {
    margin-top: -4rem;
    font-size: 20px;
}

@media(max-width:500px){
    section.section-global .col-lg-3.col-md-12 .H2 {
        text-align: center;
    }
}

  