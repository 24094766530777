.contact-section{
    position: relative;
    overflow: hidden;
    .col-cont{
        z-index: 100;
    }
    #form{
        align-items: center;
        padding-top: 100px;

    }
    
    
    .h1{
        font-size: 50px;
        font-family:serif;
        font-weight:lighter;
        padding-left: 250px;
        margin-bottom: 50px;
        text-decoration: underline 2px;
        color: rgb(30, 60, 0);

    }
    .bg_1{
        position: absolute;
        bottom: 100px;
        left: -3.1vw;
        z-index: 0;
        height: 20vw;
        @media (max-width: 767.98px) {
            bottom: 50px;
            height: 25vw;
        }
    }
    .bg_2{
        position: absolute;
        top: 50px;
        right: -3.1vw;
        z-index: 0;
        height: 13vw;
    }
    h2 .txt-highlight{
        position: relative;
        .scribble{
            position: absolute;
            right: -50px;
            height: calc(2rem + 1vw);
        }
    }
}

.bttn{
    text-align: center;
}
#buttn{
    width: 100px;
    background-color: rgb(224 69 102);

}
#placeholder-color{
    text-transform: capitalize;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

// @media(min-width:768px){
//     .contact_main_image{
//         margin-left: 130px !important;
//     }
// }


@media(max-width:500px){
.form-label{
    font-size: 15px;
    display: flex;
}
#color-portfolio{
    font-size: 27px;
    text-align: center;
}
}

.form-label{
    font-size: 17px;
    display: flex;
}

.contact_main_img2 {
    text-align: center;
}
.contact_main_img1{
    text-align: center;
}

