#service{
    background-color: white;
}
#mainservice{
    background-color: #e7f0fd;

}
#color{
    color:rgb(6, 0, 72);  
}
// #color1{
//     color:white;  
// }
