// #E1{
//     height: 450px;

// }
// #E2{
//     width: 700px;
//     height: 450px;

// }
.project-detail-container{
    .case-study{
        img{
            border-radius: 15px;
            box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);
        }
    }  
    .case-study-section{
        max-width: 1000px;
        // height: 500px;
    margin-left: auto;
    margin-right: auto;
        p{
            margin: 13px 0;
        }
        h2{
            margin-top: 30px;
        }
    }
}
#E2{
    background-color: white;
}
// #e-fashion{
//     align-items: center;
//     padding-left: 550px;
//     margin-top:50px;
// }
.aboutus{
    margin-bottom: 300px;
}
.efashion h2{
margin-top: -90px;
}
