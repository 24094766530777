.about-container {
    .capability-card {
        .count {
            font-size: 18px;
            font-weight: 700;
            color: #fefefe;
            background: var(--color-primary);
            border-radius: 50%;
            height: 30px;
            min-width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0;
        }
    }

    .video-thumb {
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        transition: 0.3s;
        box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);

        @media (min-width: 992px) {
            max-width: 500px;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: 0.3s;
        }

        &:hover {
            filter: brightness(0.8);
            box-shadow: 0 0 0 1px var(--border-brutal);

            svg {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }
    }

    .counter-section {
        padding: 60px 0;
    }

    .team-member {
        img {
            height: 200px;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 15px;
            box-shadow: 0 0 0 1px var(--border-brutal), 5px 5px 0 var(--border-brutal);

            @media (max-width: 575.98px) {
                height: 75vw;
            }
        }
    }
}

// .container{
//     margin-bottom: 16px;
// }

.first_section {
    padding: 0 0;
    border-bottom: none !important;
    background-color: white !important;
    // background-image: url("https://wallpapers.com/images/featured/3ao0esn9qknhdudj.jpg");

}

.product_para {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
}


.product_h3 {
    font-size: 30px;
    color: rgb(6, 0, 72);

}

.main_h5 {
    color: rgb(6, 0, 72);
}

.product_features {
    padding-left: 60px !important;
}

@media(max-width:320px) {
    .product_features {
        padding-left: 65px !important;
    }

    .counting_product {
        gap: 40px !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

    }


    .grid-section {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center;
    }

    .counting_product p {
        font-size: 15px;
    }

    .product_counters {
        text-align: center;
    }

}



@media(max-width:500px) {
    .grid-section {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center;
    }

    .product_h3 {
        font-size: 28px !important;
    }

    .product-about {
        display: grid;
        grid-template-columns: none !important;
    }

    .product_features {
        padding-left: 20px !important;
    }

    .prod_img1 {
        width: 100%;
    }

    .prod_img2 {
        width: 100%;
    }

    .product_feature_icon {
        width: 45px !important;
    }

    .section4_h2 {
        margin-left: 0px !important;
    }

    .product_feature_icon {
        border-radius: 10px !important;
    }

    .vedio_heading {
        font-size: 30px !important;
    }

    .video_h2 {
        font-size: 25px !important;
    }

    .option_li {
        font-size: 16px;
    }

    .section4_h2 {
        font-size: 30px !important;
    }

    .grid_para {
        margin-left: 5px !important;
    }

    .grid_para {
        font-size: 16px !important;
    }

    .accordion-flush .accordion-item .accordion-button {
        font-size: 16px !important;
    }

    .accordion-body {
        font-size: 14px;
    }
}

.about-container .counter-section2 {
    padding: 85px 0;
    background-color: rgb(0, 66, 97);
    color: black;
    border-bottom: 1px solid black;
}

.product_about {
    background-color: rgb(169 204 255 / 25%);
}

.counting_product {
    gap: 165px;
    color: white;
}

.counting_product p {
    color: white;
}

.single_box1 {
    border: 1px solid rgb(235, 235, 235);
    background-color: rgb(169 204 255 / 25%);
    border-radius: 30px;
    padding: 5%;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 112px;
}

.single_box2 {
    border: 1px solid rgb(235, 235, 235);
    background-color: rgb(169 204 255 / 25%);
    border-radius: 30px;
    padding: 5%;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}

.prod_img1 {
    width: 100%;
}

.prod_img2 {
    width: 100%;
    margin-bottom: -36px;
    margin-top: 24px;
}

.single_box2_content {
    margin-top: 85px;
}


.grid-section {
    display: grid;
    grid-template-columns: 35% 35% 35%;
    justify-content: center;
}

.grid-section-images {
    width: 75%;
}

.grid-section-images :hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: 400ms;
}

@media (min-width:502px) and (max-width:1440px) {
    .grid-section {
        margin-left: 20px !important;
    }
}


@media (min-width:576px) and (max-width:765px) {
    .grid-section {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-left: 40px !important;
    }

    // .counting_product {
    //     gap: 0px;
    //     text-align: center;
    // }
}

@media (min-width:500px) and (max-width:579px) {
    .grid-section {
        display: grid;
        grid-template-columns: 50% 50%;
        margin-left: 40px !important;
    }

    .about-container .team-member img {
        height: 32vw;
    }

    .product_h3 {
        font-size: 27px !important;
    }


}


@media (min-width:992px) and (max-width:1398px) {
    .counting_product {
        gap: 110px;
        text-align: center;
    }
}

@media (min-width:770px) and (max-width:973px) {
    .counting_product {
        gap: 0px !important;
        text-align: center;
    }
}

@media (min-width:990px) and (max-width:973px) {
    .counting_product {
        gap: 0px !important;
        text-align: center;
    }
}

@media(min-width:500px) and (max-width:768px) {
    .counting_product {
        gap: 0px !important;
        text-align: center;
    }
}

@media(max-width:504px) {
    .counting_product {
        gap: 46px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}



// FAQ //

.faq-section {
    background-color: white;
}

button.accordion-button.collapsed {
    font-size: 20px;
}

.accordion-body {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

.faqs {
    text-align: center;
    color: rgb(6, 0, 72);

}



.product_feature_icon {
    width: 80px;
    height: fit-content;
    border-radius: 15px;

}

.product_icon_image {
    display: grid;
    width: auto;
}

.product-about {
    display: grid;
    grid-template-columns: 50% 50%;
}

.simplyfy_customer {
    width: auto;
}

.product_features {
    width: auto;
}

.product_featute_div1 {
    display: flex;
    gap: 20px;
}

.product_featute_div2 {
    display: flex;
    gap: 20px;

}

.product_featute_div3 {
    display: flex;
    gap: 20px;

}



.banner_backg_img {
    position: relative;
    display: grid;
    grid-template-columns: 30% 70%;
    place-items: center;
    width: 100%;
    grid-template-areas: "content video";

}

.vedio_text h2{
    font-size: 50px !important;
    margin-left: 116px;
    color: rgb(2, 2, 96);
}



@media(max-width:768px) {
    .product-about {
        display: grid;
        grid-template-columns: none !important;
    }

    .product_row {
        display: grid;
        // grid-template-rows: 50% 50%;
        grid-template-columns: none !important;
    }

    .interior {
        margin-bottom: 83px !important;

    }

    .vedio_text h2 {
        text-align: center;
        font-size: 38px !important;
    }

    .modal-window>div {
        width: 60% !important;
    }
    .vedio4 {
        width: 100% !important;
    }
}

@media (min-width:726px) and (max-width:991px) {
    .product-about {
        display: grid;
        grid-template-columns: none !important;
    }
}

@media (min-width:990px) and (max-width:992px) {
    .vedio4 {
width: 100% !important;
    }
}

@media(min-width:769px) and (max-width:992px){
    .banner_backg_img {
        width: 200% !important;
    }
}

@media (min-width:767px) and (max-width:944px){
    .vedio_text h2 {
        font-size: 35px !important;
    }
}

@media(min-width:545px) and (max-width:775px){
    .vedio_text h2 {
        font-size: 30px !important;
    }
    .modal_btn{
        margin-left: 110px !important;

    }
    .vedio_text{
        font-size: 12px !important;
    }

}

@media(min-width:741px) and (max-width:760px){
    .vedio_text h2 {
        font-size: 41px !important;
        margin-right: 55px !important;    
    }
    .modal_btn{
        margin-right: 50px !important;

    }
}


@media(min-width:519px) and (max-width:547px){
.banner_backg_img{
    margin-top: 55px !important;
}
.vedio_text h2{
    font-size: 30px !important;
    margin-right: 55px !important;
}
.modal_btn{
    margin-right: 53px !important;
}
}

@media(min-width:499px) and (max-width:536px){
    .vedio_text h2 {
        font-size: 29px !important;
    }
    .vedio_text {
        margin-right: 64px !important;
        }
}
@media(min-width:518px) and (max-width:547px){
    .vedio_text h2{
        margin-right: 0px !important;
    }
}

@media(min-width:452px) and (max-width:501px){
    .vedio_text h2{
        font-size: 34px !important;
    }
    .modal_btn {
        margin-left: 120px !important;
    }
}



.bg-shade-blue {
    background: none;
}

.section4_h2 {
    margin-top: -40px;
    color: rgb(6, 0, 72);

}

.grid_description {
    font-family: Arial, Helvetica, sans-serif;
}

.grid_para {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
}


// vedio section //


.vedio_text {
    text-align: center;
    grid-area: content;
    font-size: 15px;
    // margin-left: 165px;
}

// .vedio_text h2{
// font-size: 30px;
// margin-left: 100px;
// }



// .content {
//     position: fixed;
//     bottom: 0;
//     background: rgba(0, 0, 0, 0.5);
//     color: #f1f1f1;
//     width: 100%;
//     padding: 20px;
//   }



.vedio1 {
    width: 100%;
}

.vedio_heading {
    font-size: 265%;
    text-align: center;
    margin-top: -59px !important;
    color: rgb(6, 0, 72);

}

.video_h2 {
    color: rgb(6, 0, 72);
    font-size: 33px;
}


.accordion-button:not(.collapsed) {
    color: none !important;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125);
}



// banner_img //

.product_banner_img {
    width: 100%;
    object-fit: cover;
}


@media (min-width: 992px) {
    .banner_backg_img {
        flex: 0 0 auto;
        width: 171.4%;
    }
}


.banner_video {
    max-width: 100%;
    height: auto;
    grid-area: video;
}

// .row>* {
//     padding-right:0 !important; 
//     padding-left:0 !important; 
// }
.product_row {
    --bs-gutter-x: none !important;
    display: grid;
    grid-template-columns: 50% 50%;
}

@media (max-width: 500px) {
    .section-global {
        padding: 60px 0;
    }
}






// // contact form//

.contact-form {
    // width: 300px;
    margin: 0 auto;
}

input,
textarea {
    width: 100%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #020202;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
    transition: border-color .2s;
}

.contact-form input[type="text"],
.contact-form textarea {
    width: 100%;
    padding: 5px;
    border-radius: 7px;
}

.contact-form input[type="submit"] {
    background-color: rgb(1, 1, 63);
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}



.contact-form-popup {
    text-align: center;
}


.close-icon {
    font-size: 35px;
    cursor: pointer;
}

.popup_form {
    font-family: auto;
    font-size: 17px;
}


.modal-window {
    position: fixed;
    // background-color: rgba(255, 255, 255, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;

    &:target {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }

    &>div {
        width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2em;
        background-image: linear-gradient(#e7e9ff, #acbfff);
    }

    header {
        font-weight: bold;
    }

    h1 {
        font-size: 150%;
        margin: 0 0 15px;
    }
}

.modal-close {
    color: #0a0a0a;
    line-height: 50px;
    font-size: 110%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: black;
    }
}

/* Demo Styles */

html,
body {
    height: 100%;
}

html {
    font-size: 18px;
    line-height: 1.4;
}


.interior a {
    text-decoration: none;

}

.interior a:hover {
    color: rgb(164, 187, 167);
}


//   .container {
//     display: grid;
//     justify-content: center;
//     align-items: center;
//   }

.modal-window {
    &>div {
        border-radius: 1rem;
        margin-top: 50px;
        //   display: grid;
        // grid-template-columns: 50% 50%;
        width: 35%;
    }
}

.modal-window div:not(:last-of-type) {
    margin-bottom: 15px;
}

.logo {
    max-width: 150px;
    display: block;
}

small {
    color: lightgray;
}

.modal_btn {
    background-color: white;
    padding: 1em 1.5em;
    border-radius: 0.5rem;
    text-decoration: none;
    margin-left: 100px;

    i {
        padding-right: 0.3em;
    }
}

.interior {
    text-align: center;
}


.modal_btn {
    bottom: 270px;
    left: 100px;
    border: 1px solid black;
    background-color: #003f1b;
    color: white;
}

.modal-window:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    background-color: rgb(7 6 16 / 45%);
}

.popup_form label {
    margin-top: 20px;
}


.vedio4{
    width: 200% ;
    border-radius: 15px;
}



@media(max-width:500px) {
    .modal-window>div {
        width: 290px;
        padding: 0 !important;
    }

    .modal_btn{
        margin-left: 120px !important;
    }
    
    .popup_form {
        border: none !important;
        padding: 15px 22px;
    }

    .vedio_text {
        top: 150px;
        left: -62px;
        grid-area: none !important;
        margin-top: 242px !important;
        margin-left: -140px;
        font-size: 12px;
    }

    .interior_a {
        top: -28px !important;
        font-size: 11px;
        margin-top: 40px;
    }

    .modal_btn {
        bottom: 0 !important;
    }

    // .product_row {
    //     --bs-gutter-x: none !important;
    //     display: grid;
    //     grid-template-rows: 50% 50% !important;
    //     grid-template-columns: none !important;
    // }
    .container {
        margin-bottom: 0 !important;
    }

    .vedio_text h2 {
        text-align: center;
        font-size: 37px;
    }

    .popup_form_img {
        display: none !important;
    }

    .modal-window>div {
        width: 307px !important;
    }

    .banner_backg_img {
        display: grid;
        // grid-template-rows: 30% 70%;
        grid-template-columns: none !important;
        grid-template-areas: none !important;
    }
    .banner_video{
        grid-area: none !important;
        margin-bottom: 200px;
    }

    .vedio4{
        width:100% !important;
    }
}




@media (max-width:1351px) {
    .modal_btn {

        bottom: 270px;
        left: 100px;
        border: 1px solid black;
        background-color: #003f1b;
        color: white;
    }
}


.popup_form_img {
    width: 100%;
    height: 422px;
}


@media(max-width:1024) {
    .popup_form {
        height: 10px;
        width: 84% !important;
        padding: 13px !important;
    }
}

@media (min-width: 992px) {
    .banner_backg_img {
        flex: 0 0 auto;
        width: 198.4% !important;
    }
}


@media(min-width:769px) and (max-width:991px){
    .vedio4{
        width: 100% !important;
    }
}
