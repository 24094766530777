.testimonial-card{
    background: #FFFFFF;
    padding: 24px 20px;
    border-radius: 15px;
    height: 590px;
    box-shadow: 0 0 0 1px var(--border-brutal), 4px 4px 0 var(--border-brutal);
}
.txt-1 {
// font-family: cursive;
font-size: 15px;
}
.testimonial-card{
    background-color: rgb(255, 255, 255);
}
.logo{
    width: 200px;
}
#testmo:hover{
    cursor: pointer;
    transform: scale(1.03);
    transition: 700ms;
}
#review-p{
    font-size: 16px;
}
#u-name{
    font-size: 14px;
    padding-bottom: -30px;

}
#u-position{
    font-size: 12px;
}