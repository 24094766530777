footer{
    margin-top: 24px;
}
.links{
    margin-left: 100px;

}
#title{
    margin-left: 100px;
}
.link-section{
    margin-left: 80px;
}
// div#paragraph.link-section{
//     gap: 100px;
//     justify-content: space-evenly;
// }


@media(max-width:630px){
    #mail-section{
        margin-top: 40%;    
    }
        .link-section{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: absolute;
            gap: 25px;
    }
    .links{
        margin-left: 0px;
    
    }
    #title{
        margin-left: 0px;
    }
    
    
}
@media(max-width:320px){
    #mail-section{
        margin-top: 49%;    
    }
}
.footer-section{
    padding: 60px 0;
    .link-section{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .links{
            display: flex;
            flex-direction: column;
            .link{
                color: var(--color-2);
                font-size: 16px;
                text-decoration: none;
                transition: 0.2s;
                margin: 2px 0;
                &:hover{
                    color: var(--color-primary);
                    text-decoration: underline;
                    text-underline-offset: 0.2em;
                    text-decoration-thickness: 1px;
                }
            }
        }
    }
    .contact-link{
        color: var(--color-1);
        text-decoration: none;
        &:hover{
            color: var(--color-primary);
            text-decoration: underline;
            text-underline-offset: 0.2em;
            text-decoration-thickness: 1px;
        }
    }
    .social-link{
        a{
            margin-right: 20px;
        }
    }
}
.footer-bottom{
    padding: 40px 0;
    border-top: 1px solid rgba(255,255,255,0.1);
    color: rgba(255,255,255,0.5);
}



@media(max-width:768px){


    div#paragraph.link-section{
        margin-left: 5px;
        justify-content: flex-start !important;
    }
    .footer-address {
       
        margin-top: 10px;
    }


        
}
.footer-address{
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 8px;
}

@media(min-width:772px) and (max-width:989px){
    .row.gy-4.footer-section div#paragraph {
        width: fit-content;
    }
    .row.gy-4.footer-section .link-section {
        margin-left: -100px;
    }

}


// @media(min-width:625px) and (max-width:774px){
//     .row.gy-4.footer-section div#paragraph {
//         width: fit-content;
//     }
//     .row.gy-4.footer-section .link-section {
//         margin-left: -95px;
//     }
// }

// @media(min-width:635px) and (max-width:768px){
//     .row.gy-4.footer-section div#paragraph {
//         width: fit-content;
//     }
//     .row.gy-4.footer-section .link-section {
//         margin-left: -95px;
//     }
// }

@media(min-width:637px) and (max-width:768px) {
    div#paragraph.link-section {
        margin-left: -96px;
        justify-content: flex-start !important;
    }
}